.rbc-button-link {
	width: 100%;
}

.rbc-today {
	background-color: transparent;
}

.rbc-header + .rbc-header {
	border: none;
	border-bottom: 1px solid #ddd;
}

.rbc-time-header.rbc-overflowing {
	border-right: none;
}

.rbc-event-label {
	display: none !important;
}

.rbc-event {
	padding: 0 !important;
	border-radius: 0;
	background-color: #fff;
}

.rbc-day-slot .rbc-event {
	border: none;
	min-height: 28px;
}

.rbc-day-slot .rbc-events-container {
	margin: 0;
	cursor: pointer;
}

.rbc-time-content {
	border-top: 1px solid #ddd;
}

.rbc-time-content > * + * > * {
	border: none;
}

.rbc-time-content > .rbc-time-gutter {
	border-right: 1px solid #ddd;
}

.rbc-timeslot-group {
	border-bottom: none;
}

.rbc-event.rbc-selected {
	background-color: white;
}

.rbc-event:focus,
.rbc-day-slot .rbc-background-event:focus {
	outline: none;
}

.rbc-day-slot .rbc-time-slot {
	border-top: none;
}

.rbc-time-view {
	border: none;
	border-top: 1px solid #ddd;
}

.rbc-current-time-indicator {
	position: absolute;
	z-index: 5;
	height: 1px;
	border: 1px solid #eb5454;
	pointer-events: none;
}

.rbc-current-time-indicator::before {
	display: inline-block;
	content: "";
	background-color: #eb5454;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	margin-left: -2px;
	margin-bottom: 14px;
	margin-top: -6px;
}

.rbc-row-bg {
	cursor: pointer;
}

.rbc-row-content {
	cursor: pointer;
}
